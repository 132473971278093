import { Connectors } from "use-wallet";
import BinanceLogo from "@assets/wallets/binance.svg";
import MetamaskLogo from "@assets/wallets/metamask.svg";
import TrustLogo from "@assets/wallets/trust.svg";
import { BigNumber } from "ethers";

export enum Action {
  Stake = 1,
  Unstake,
}

export enum Token {
  // PHB = "PHB",
  JIT = "OSCAR",
  JIT_OSCAR_ARB = "OSCAR ",
  JIT_OSCAR = "OSCAR  ",
  JIT_OSCAR_LP = "OSCAR   ",
  // JIT_OSCAR_ARB_DEPRECATED = "JIT-BNB LP Deprecated",
  // JIT_OSCAR_ARB_LEGACY = "JIT-BNB LP Legacy",
}

export const TokenName = {
  [Token.JIT]: "OSCAR",
  [Token.JIT_OSCAR_ARB]: "OSCAR ",
  [Token.JIT_OSCAR]: "OSCAR  ",
  [Token.JIT_OSCAR_LP]: "OSCAR   ",
};

export const TokenShortName = {
  [Token.JIT]: "OSCAR",
  [Token.JIT_OSCAR_ARB]: "OSCAR ",
  [Token.JIT_OSCAR]: "OSCAR  ",
  [Token.JIT_OSCAR_LP]: "OSCAR   ",
};

export enum SupportedWallet {
  Metamask = "Metamask",
  Binance = "Binance",
  Trust = "Trust",
}

declare global {
  type ActionEnum = Action;
  type TokenEnum = Token;
  type SupportedWalletEnum = SupportedWallet;

  interface WalletDetail {
    key: SupportedWalletEnum;
    label: string;
    logo: string;
    connectorId: keyof Connectors;
  }
}

export const SUPPORTED_WALLETS: WalletDetail[] = [
  {
    key: SupportedWallet.Metamask,
    label: "Metamask",
    logo: MetamaskLogo,
    connectorId: "injected",
  },
  // {
  //   key: SupportedWallet.Binance,
  //   label: "Binance Wallet",
  //   logo: BinanceLogo,
  //   connectorId: "bsc",
  // },
  {
    key: SupportedWallet.Trust,
    label: "Trust Wallet",
    logo: TrustLogo,
    connectorId: "injected",
  },
];

export const CHAIN_NAME_MAP: {
  [chain: number]: string;
} = {
  56: "BSC Mainnet",
  97: "BSC Testnet",
  1: "Ethereum",
};

export const TOKEN_ADDRESS: {
  [chain: number]: {
    [t in Token]: string;
  };
} = {
  56: {
    [Token.JIT]: "0x000000000000000000000000000000000000dead",
    [Token.JIT_OSCAR_ARB]: "0x000000000000000000000000000000000000dead",
    [Token.JIT_OSCAR]: "0x000000000000000000000000000000000000dead",
    [Token.JIT_OSCAR_LP]: "0x000000000000000000000000000000000000dead",
  },
  97: {
    [Token.JIT]: "0x000000000000000000000000000000000000dead",
    [Token.JIT_OSCAR_ARB]: "0x000000000000000000000000000000000000dead",
    [Token.JIT_OSCAR]: "0x000000000000000000000000000000000000dead",
    [Token.JIT_OSCAR_LP]: "0x000000000000000000000000000000000000dead",
  },
  1: {
    [Token.JIT]: "0x1365532337ee5b6360dD4ccAA74f6A7A243f79e7",
    [Token.JIT_OSCAR_ARB]: "0x1365532337ee5b6360dD4ccAA74f6A7A243f79e7",
    [Token.JIT_OSCAR]: "0x1365532337ee5b6360dD4ccAA74f6A7A243f79e7",
    [Token.JIT_OSCAR_LP]: "0x1365532337ee5b6360dD4ccAA74f6A7A243f79e7",
  },
};

// staking contract
export const STAKING_CONTRACT_ADDRESS: {
  [chain: number]: {
    [t in Token]: string;
  };
} = {
  56: {
    [Token.JIT]: "0x000000000000000000000000000000000000dead",
    [Token.JIT_OSCAR_ARB]: "0x000000000000000000000000000000000000dead",
    [Token.JIT_OSCAR]: "0x000000000000000000000000000000000000dead",
    [Token.JIT_OSCAR_LP]: "0x000000000000000000000000000000000000dead",
  },
  97: {
    [Token.JIT]: "0x000000000000000000000000000000000000dead",
    [Token.JIT_OSCAR_ARB]: "0x000000000000000000000000000000000000dead",
    [Token.JIT_OSCAR]: "0x000000000000000000000000000000000000dead",
    [Token.JIT_OSCAR_LP]: "0x000000000000000000000000000000000000dead",
  },
  1: {
    [Token.JIT]: "0x542325eBFcAbA631402202551DF0746d05E7d8E1",
    [Token.JIT_OSCAR_ARB]: "0xbCa2632Efe8252C36AeEC2aD8C9657399CafeC65",
    [Token.JIT_OSCAR]: "0x542325eBFcAbA631402202551DF0746d05E7d8E1",
    [Token.JIT_OSCAR_LP]: "0x542325eBFcAbA631402202551DF0746d05E7d8E1",
  },
};

// const EnvChainId = parseInt(process.env.REACT_APP_CHAIN_ID);

export const ChainId = 1 ;//[56, 97].indexOf(EnvChainId) > -1 ? EnvChainId : 97;
export const ChainName = CHAIN_NAME_MAP[ChainId];
export const TokenAddresses = TOKEN_ADDRESS[ChainId];
export const StakingAddresses = STAKING_CONTRACT_ADDRESS[ChainId];

// BSC 3 seconds per block
export const BSC_BLOCK_TIME = 3;

// BSC Blocks per year
export const BLOCKS_PER_YEAR = BigNumber.from(
  (60 / BSC_BLOCK_TIME) * 60 * 24 * 365
); // 10512000

export const DEPRECATED_TOKENS = [
  // Token.JIT_OSCAR_ARB_DEPRECATED,
  // Token.JIT_OSCAR_ARB_LEGACY,
];
